/* eslint-disable */

import { useState, useRef, useEffect } from 'react';
import React from 'react';
import './index.css';
import CryptoJS from 'crypto-js';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Space, Tag } from 'antd';
// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Card,
    CardContent,
    Chip,
    ClickAwayListener,
    Divider,
    Grid,
    InputAdornment,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    OutlinedInput,
    Paper,
    Popper,
    Stack,
    Switch,
    Typography
} from '@mui/material';
import { IconTie, IconWorldWww, IconCrown } from '@tabler/icons';
// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import UpgradePlanCard from './UpgradePlanCard';
import User1 from 'assets/images/users/user-round.svg';

// assets
import { IconLogout, IconSearch, IconSettings, IconUser } from '@tabler/icons';
import { axiosInstanceLive } from 'instance/Axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Buffer } from 'buffer';
import { handleCatchError, logoutFunction } from 'utils/CatchHandler';
import BcAgreement from './BcAgreement';
import { setBcTrue, setBcFalse } from 'redux/BcAgreementSlice';
import { useDispatch } from 'react-redux';
import { convertPremiumBackToBC, getProfile, logOutGet } from 'utils/API/Merchant APIs/MerchantApiCalls';
import { checkMerchantOrBCAPI } from 'utils/API/Merchant APIs/MerchantApiCalls';
import BcConvertModal from 'views/Components/BcConvertModal';
import Spinner from 'views/Components/LoadingSinner';
import { globalNotifySuccess } from 'views/utilities/AlertToast';
import ConfirmationDialog from 'views/Components/Dialogs/ConfirmationDialoge';
import { fetchProfileAsync } from 'redux/ProfileUpdationSlice';

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const customization = useSelector((state) => state.customization);
    const [agentClassData, setAgentClassData] = useState('');
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [open, setOpen] = useState(false);
    const [profileName, setProfileName] = useState('');
    const [isShow, setIsShow] = useState(false);
    const [showAgentCode, setShowAgentCode] = useState(false);
    const [showCodes, setShowCodes] = useState(false);
    const [bcmodal, setBcModal] = useState('');
    const dispatch = useDispatch();
    const [merchantModalOpen, setMerchantModalOpen] = useState(false);
    const [isBcConvertPending, setIsBcConvertPending] = useState(false);
    const [isCheckLoading, setIsCheckLoading] = useState(false);
    const [isBcAgent, setIsBcAgent] = useState(false);
    const [premiumBcStatus, setPremiumBcStatus] = useState(null);
    const [type, setType] = useState('');
    const [profileImageDisp, setProfileImageDisp] = useState('');
    const [merchantProfileInfo, setMerchantProfileInfo] = useState({});
    const [isBackToBcLoading, setIsBackToBcLoading] = useState(false);
    const [backToBcModalOpen, setBackToBcModalOpen] = useState(false);
    const [showEmployee, setShowEmployee] = useState(false);
    const [employeeType, setEmployeeType] = useState('');
    const showBc = useSelector((state) => state?.bcAgreement?.bcAgreementShow);
    const image = useSelector((state) => state.profileUpdation?.image);
    const typeFromLogin = localStorage.getItem('type');
    const isSubdistributor = localStorage.getItem('isSubdistributor');
    const mode = useSelector((state) => state.theme.mode);
    const shouldshowEsign = localStorage.getItem('shouldshowEsign');
    const [apiPortalShow, setApiPortalShow] = useState(false);
    const portalStatus = localStorage.getItem('portalStatus');
    const whitelabelStatus = localStorage.getItem('whitelabelStatus');
    useEffect(() => {
        if (portalStatus) {
            setApiPortalShow(true);
        }
    }, []);
    useEffect(() => {
        if (showBc) {
            setOpen(false);
        }
    }, [showBc]);
    useEffect(() => {
        if (image) {
            setProfileImageDisp(image);
        }
    }, [image]);
    // console.log(userType);
    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     **/

    // const decryptedData = decryptFunction(encryptedData);
    //decryption

    const agentCode = localStorage.getItem('agentcode');
    const role = localStorage?.getItem('employeeRole');
    //decryption function
    const encryptedData = agentCode;
    const agent_Code = decryptFunction(encryptedData);
    const merchantEmpName = localStorage.getItem('merchantEmpName');
    const isEmployee = localStorage.getItem('isEmployee');
    //decryption
    function decryptFunction(encryptedData) {
        if (agentCode) {
            const keyHex = '675228de68b1903428c6288e5b4e16b5bc054dbe4f459a0f2bc4e813c0f14912';
            const key = CryptoJS.enc.Hex.parse(keyHex);

            let ciphertext = encryptedData?.split('_')?.pop();
            // console.log('ciphertext', ciphertext);
            const first32Characters = encryptedData?.substring(0, 32);

            const iv = CryptoJS.enc?.Hex?.parse(first32Characters);

            const decrypted = CryptoJS.AES.decrypt({ ciphertext: CryptoJS.enc.Base64.parse(ciphertext) }, key, {
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            }).toString(CryptoJS.enc.Utf8);
            return decrypted;
        }
    }
    const loginType = localStorage.getItem('type');
    const label1 = localStorage.getItem('label1');

    useEffect(() => {
        if (loginType === 'distributor') {
            dispatch(fetchProfileAsync());
            if (role === 'teammanager' || role === 'employee' || role === 'teamleader') {
                setEmployeeType(role);
                setShowEmployee(true);
            }
        }
    }, [loginType]);
    const bc_agent_status = localStorage.getItem('bc_agent_status');
    useEffect(() => {
        if (
            type === 'Merchant' ||
            type === 'BcAgent' ||
            type === 'Premium BC Class 1' ||
            type === 'Premium BC Class 2' ||
            type === 'Premium BC Class 3' ||
            typeFromLogin === 'merchants'
        ) {
            setShowCodes(true);
            fetchProfile();
        }
    }, [type]);
    const fetchProfile = async () => {
        const token = localStorage.getItem('token');
        // console.log(token);
        try {
            const response = await getProfile();
            // console.log('response s', response);
            const data = response?.data;
            setProfileImageDisp(response?.data?.data?.profile_img);
            localStorage.setItem('kycKeyStatus', response?.data?.data?.merchant?.matm_kyc_status);
            const profileInfo = data.data?.merchant;
            setMerchantProfileInfo(profileInfo);
            setAgentClassData(profileInfo?.agent_class);
        } catch (error) {
            console.log(error.message);
            handleCatchError(error);
        }
    };
    // useEffect(() => {
    //     if (type === 'merchants') {
    //         fetchProfile();
    //     }
    // }, [type]);
    const [greeting, setGreeting] = useState('');
    //navigate to profile page
    function handleProfile() {
        setOpen(false);
        const type = localStorage.getItem('type');
        const employeeRole = localStorage.getItem('employeeRole');
        if (type === 'merchants') {
            navigate('/merchants/profile');
        } else if (type === 'superdistributor') {
            navigate('/super-distributor/profile');
        } else if (type === 'distributor' && employeeRole === 'undefined') {
            navigate('/distributor/profile');
        } else if (type === 'distributor') {
            if (employeeRole === 'employee' || employeeRole === 'teammanager' || 'teamleader') {
                navigate('/employee/profile');
            }
        } else if (type === 'superdistributor_employee') {
            navigate('/super-distributor-employee/profile');
        }
    }
    function handlePortal() {
        const newTab = window.open('https://bc-old.acemoney.in/#/sign-in');
        newTab.focus();
        navigate('/merchant-home');
    }
    const handleGoToClassCards = () => {
        setOpen(false);
        navigate('premium-bc/premium-bc-class-cards');
    };
    const handleBcConversion = async () => {
        setOpen(false);
        //CHECKING FOR  MERCHANT OR BC
        setIsCheckLoading(true);
        const agentid = localStorage.getItem('id');
        const payload = { agentid };
        const merchantBcCheckResponse = await checkMerchantOrBCAPI(payload);
        setIsCheckLoading(false);
        // console.log('globalMerchantPermission', globalMerchantPermission);
        const agentType = merchantBcCheckResponse?.data?.agentType;
        const pendingStatus = merchantBcCheckResponse?.data?.isPending;
        setIsBcConvertPending(pendingStatus);

        if (agentType === 'Merchant' || pendingStatus || shouldshowEsign === 'true') {
            setMerchantModalOpen(true);

            return;
        } else if (agentType === 'BcAgent') {
            navigate('/upgrade-premium-bc');
        }
        //CHECKING ENDS HERE
    };
    const handleBcModalClose = () => {
        setMerchantModalOpen(false);
    };
    const handleBackToBcClose = () => {
        setBackToBcModalOpen(false);
    };
    const handleBackToBC = () => {
        setBackToBcModalOpen(true);
    };
    const confirmBackToBc = async () => {
        setIsBackToBcLoading(true);
        const agentid = localStorage.getItem('id');
        const payload = { agentid };
        const response = await convertPremiumBackToBC(payload);
        if (response?.data?.status) {
            globalNotifySuccess('Successfully converted back to BC agent.');
            window.location.reload();
        }
        setIsBackToBcLoading(false);
    };
    const truncateName = (name, maxLength) => {
        if (name.length <= maxLength) {
            return name;
        }
        return name.substring(0, maxLength) + '...';
    };
    const notify = () =>
        toast.error('Something went wrong, please try again.', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light'
        });

    const logoutFunction = async () => {
        try {
            const response = await logOutGet();
            // console.log('logout response :', response);
            if (response.data.success) {
                localStorage.removeItem('token');
                localStorage.clear();
                navigate('/');
                window.location.reload();
                // console.log('Logout');
            } else {
                // notify();
                window.location.reload();
            }
        } catch (error) {
            console.log(error.message);
            handleCatchError(error);
        }
    };
    const checkIsMerchantOrBc = async () => {
        const agentid = localStorage.getItem('id');
        const payload = { agentid };
        const merchantBcCheckResponse = await checkMerchantOrBCAPI(payload);
        const agentType = merchantBcCheckResponse?.data?.agentType;
        setType(agentType);
        if (agentType === 'BcAgent') {
            setIsShow(true);
            setIsBcAgent(true);
            setPremiumBcStatus(null);
        } else if (agentType === 'Premium BC Class 1' || agentType === 'Premium BC Class 2' || agentType === 'Premium BC Class 3') {
            setPremiumBcStatus(agentType);
            setIsBcAgent(false);
            setIsShow(true);
        } else if (agentType === 'Merchant') {
            setIsShow(true);
        } else {
            setIsBcAgent(false);
        }
    };
    useEffect(() => {
        const date = new Date();
        const hour = date.getHours();
        if (hour >= 6 && hour < 12) {
            setGreeting('Good morning');
        } else if (hour >= 12 && hour < 16) {
            setGreeting('Good afternoon');
        } else {
            setGreeting('Good evening');
        }
    }, []);
    const anchorRef = useRef(null);
    const handleLogout = async () => {
        logoutFunction();
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleListItemClick = (event, index, route = '') => {
        setSelectedIndex(index);
        handleClose(event);

        if (route && route !== '') {
            navigate(route);
        }
    };
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
        if (open) {
            checkIsMerchantOrBc();
        }
    }, [open]);
    const firstname = localStorage.getItem('firstName');
    const lastname = localStorage.getItem('LastName');
    const name = localStorage.getItem('name');
    useEffect(() => {
        if (
            type === 'Merchant' ||
            type === 'BcAgent' ||
            type === 'Premium BC Class 1' ||
            type === 'Premium BC Class 2' ||
            type === 'Premium BC Class 3' ||
            typeFromLogin === 'merchants'
        ) {
            if (isEmployee === 'true') {
                setProfileName(merchantEmpName);
            } else {
                if (merchantProfileInfo?.agname && merchantProfileInfo?.lname) {
                    setProfileName(merchantProfileInfo?.agname + ' ' + merchantProfileInfo?.lname);
                    return;
                }
                if (name && lastname && name !== 'undefined' && lastname !== 'undefined') {
                    setProfileName(name + ' ' + lastname);
                    return;
                }
                setProfileName(name);
            }
        } else {
            if (name && lastname && name !== 'undefined' && lastname !== 'undefined') {
                setProfileName(name + ' ' + lastname);
                return;
            }
            if (name && name !== 'undefined') {
                setProfileName(name);
            } else {
                setProfileName(firstname + ' ' + lastname);
            }
        }
    }, [merchantProfileInfo]);
    useEffect(() => {
        // const type = localStorage.getItem('type');
        if (type === 'Merchant' || type === 'BcAgent' || premiumBcStatus || typeFromLogin === 'merchants') {
            setIsShow(true);
            setShowAgentCode(true);
            setShowCodes(true);
        }
        if (typeFromLogin === 'superdistributor' || typeFromLogin === 'distributor' ||typeFromLogin==='superdistributor_employee') {
            setIsShow(true);
        }
        checkIsMerchantOrBc();
    }, []);
    const employeeFname = localStorage.getItem('employeeFname');
    const employeeLname = localStorage.getItem('employeeLname');

    useEffect(() => {
        const employeeRole = localStorage.getItem('employeeRole');
        if (employeeFname && employeeRole === 'employee' && type === 'distributor') {
            setProfileName(employeeFname + ' ' + employeeLname);
        }
    }, [employeeFname]);
    // const handleBc = () => {
    //     dispatch(setBcTrue());
    //     // const data = true;
    //     // BcAgreement(data);
    //     setBcModal(true);
    // };
    // BcAgreement();
    return (
        <>
            <Chip
                className={mode === 'dark' ? 'goldenChip' : ''}
                sx={{
                    height: '48px',
                    alignItems: 'center',
                    borderRadius: '27px',
                    transition: 'all .2s ease-in-out',
                    borderColor: theme.palette.primary.light,
                    background: theme.palette.primary.light,
                    '&[aria-controls="menu-list-grow"], &:hover': {
                        borderColor: theme.palette.primary.main,
                        background: theme.palette.primary.main,
                        color: theme.palette.primary.dark,
                        '& svg': {
                            stroke: theme.palette.primary.light
                        }
                    },
                    '& .MuiChip-label': {
                        lineHeight: 0
                    }
                }}
                icon={
                    <Avatar
                        src={profileImageDisp}
                        sx={{
                            ...theme.typography.mediumAvatar,
                            margin: '8px 0 8px 8px !important',
                            cursor: 'pointer'
                        }}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        color="inherit"
                    />
                }
                label={
                    <div className={premiumBcStatus ? 'crowned-name' : ''}>
                        <Typography variant="body1" className={mode === 'dark' ? 'nameText' : ''} sx={{ marginRight: '8px' }}>
                            {truncateName(profileName, 20)}
                        </Typography>
                        {premiumBcStatus && (
                            <IconCrown
                                fill={
                                    premiumBcStatus === 'Premium BC Class 1'
                                        ? 'gold'
                                        : premiumBcStatus === 'Premium BC Class 2'
                                        ? 'silver'
                                        : 'brown'
                                }
                                stroke={1}
                                size="1.5rem"
                            />
                        )}
                    </div>
                }
                variant="outlined"
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                color="primary"
            />
            <Popper
                placement="bottom-end"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 14]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions in={open} {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                    <Box sx={{ p: 2 }}>
                                        <Stack>
                                            <Stack direction="row" spacing={0.5} alignItems="center">
                                                <Typography variant="h4">{greeting},</Typography>
                                                <Typography component="span" variant="h4" sx={{ fontWeight: 400 }}>
                                                    {profileName}
                                                </Typography>
                                                {premiumBcStatus && (
                                                    <IconCrown
                                                        fill={
                                                            premiumBcStatus === 'Premium BC Class 1'
                                                                ? 'gold'
                                                                : premiumBcStatus === 'Premium BC Class 2'
                                                                ? 'silver'
                                                                : 'brown'
                                                        }
                                                        stroke={1}
                                                        size="1rem"
                                                    />
                                                )}
                                            </Stack>
                                            {!showEmployee && (
                                                <Typography variant="subtitle2">
                                                    {isEmployee === 'true'
                                                        ? 'Merchant Employee'
                                                        : isBcAgent
                                                        ? 'BC Agent'
                                                        : type
                                                        ? type
                                                        : label1
                                                        ? label1
                                                        : isSubdistributor === 'true'
                                                        ? 'SUB DISTRIBUTOR'
                                                        : loginType}
                                                </Typography>
                                            )}
                                            {showEmployee && <Typography variant="subtitle2">{employeeType}</Typography>}
                                            {showCodes && (
                                                <>
                                                    <Typography variant="subtitle2" color="success" style={{ fontWeight: 'bold' }}>
                                                        Agent Code: {agent_Code}
                                                    </Typography>
                                                    {/* <Tag
                                                style={{
                                                    fontSize: 'small',
                                                    padding: '2px',
                                                    border: 'none',
                                                    backgroundColor: 'lightgreen',
                                                    height: 'auto',
                                                    width: 'auto'
                                                }}
                                            >
                                                Agent Class: {agentClassData}
                                            </Tag> */}
                                                    <Typography variant="subtitle2" color="success" style={{ fontWeight: 'bold' }}>
                                                        Agent Class: {agentClassData}
                                                    </Typography>
                                                </>
                                            )}
                                        </Stack>
                                        {/* <OutlinedInput
                                            sx={{ width: '100%', pr: 1, pl: 2, my: 2 }}
                                            id="input-search-profile"
                                            value={value}
                                            onChange={(e) => setValue(e.target.value)}
                                            placeholder="Search profile options"
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <IconSearch stroke={1.5} size="1rem" color={theme.palette.grey[500]} />
                                                </InputAdornment>
                                            }
                                            aria-describedby="search-helper-text"
                                            inputProps={{
                                                'aria-label': 'weight'
                                            }}
                                        /> */}
                                        {/* <Divider /> */}
                                    </Box>
                                    <PerfectScrollbar style={{ height: '100%', maxHeight: 'calc(100vh - 250px)', overflowX: 'hidden' }}>
                                        <Box sx={{ p: 2 }}>
                                            <Divider />
                                            {!apiPortalShow && whitelabelStatus !== 'true' && (
                                                <>
                                                    {(isBcAgent || premiumBcStatus === 'Premium BC Class 2') && (
                                                        <UpgradePlanCard
                                                            isSilver={premiumBcStatus === 'Premium BC Class 2'}
                                                            onButtonClick={handleGoToClassCards}
                                                        />
                                                    )}
                                                </>
                                            )}
                                            {/* <Divider /> */}
                                            {/* <Card
                                                sx={{
                                                    bgcolor: theme.palette.primary.light,
                                                    my: 2
                                                }}
                                            >
                                                <CardContent>
                                                    <Grid container spacing={3} direction="column">
                                                        <Grid item>
                                                            <Grid item container alignItems="center" justifyContent="space-between">
                                                                <Grid item>
                                                                    <Typography variant="subtitle1">Start DND Mode</Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Switch
                                                                        color="primary"
                                                                        checked={sdm}
                                                                        onChange={(e) => setSdm(e.target.checked)}
                                                                        name="sdm"
                                                                        size="small"
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item>
                                                            <Grid item container alignItems="center" justifyContent="space-between">
                                                                <Grid item>
                                                                    <Typography variant="subtitle1">Allow Notifications</Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Switch
                                                                        checked={notification}
                                                                        onChange={(e) => setNotification(e.target.checked)}
                                                                        name="sdm"
                                                                        size="small"
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card> */}
                                            <Divider />
                                            <List
                                                component="nav"
                                                sx={{
                                                    width: '100%',
                                                    maxWidth: 350,
                                                    minWidth: 300,
                                                    backgroundColor: theme.palette.background.paper,
                                                    borderRadius: '10px',
                                                    [theme.breakpoints.down('md')]: {
                                                        minWidth: '100%'
                                                    },
                                                    '& .MuiListItemButton-root': {
                                                        mt: 0.5
                                                    }
                                                }}
                                            >
                                                {/* <ListItemButton
                                                    sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                    selected={selectedIndex === 0}
                                                    onClick={(event) => handleListItemClick(event, 0, '/user/account-profile/profile1')}
                                                >
                                                    <ListItemIcon>
                                                        <IconSettings stroke={1.5} size="1.3rem" />
                                                    </ListItemIcon>
                                                    <ListItemText primary={<Typography variant="body2">Account Settings</Typography>} />
                                                </ListItemButton> */}
                                                {bc_agent_status === 'true' ? (
                                                    <ListItemButton
                                                        sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                        selected={selectedIndex === 2}
                                                        onClick={handlePortal}
                                                    >
                                                        <ListItemIcon>
                                                            <IconWorldWww stroke={1.5} size="1.3rem" />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary={
                                                                <Grid container spacing={1} justifyContent="space-between">
                                                                    <Grid item>
                                                                        <Typography variant="body2">Old Portal Backup</Typography>
                                                                    </Grid>
                                                                    <Grid item></Grid>
                                                                </Grid>
                                                            }
                                                        />
                                                    </ListItemButton>
                                                ) : null}
                                                {isShow && (
                                                    <>
                                                        <ListItemButton
                                                            sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                            selected={selectedIndex === 1}
                                                            onClick={handleProfile}
                                                        >
                                                            <ListItemIcon>
                                                                <IconUser stroke={1.5} size="1.3rem" />
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                primary={
                                                                    <Grid container spacing={1} justifyContent="space-between">
                                                                        <Grid item>
                                                                            <Typography variant="body2">Profile</Typography>
                                                                        </Grid>
                                                                        <Grid item>
                                                                            {/* <Chip
                                                                        label="02"
                                                                        size="small"
                                                                        sx={{
                                                                            bgcolor: theme.palette.warning.dark,
                                                                            color: theme.palette.background.default
                                                                        }}
                                                                    /> */}
                                                                        </Grid>
                                                                    </Grid>
                                                                }
                                                            />
                                                        </ListItemButton>
                                                        {premiumBcStatus && (
                                                            <ListItemButton
                                                                sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                                selected={selectedIndex === 1}
                                                                onClick={handleBackToBC}
                                                            >
                                                                <ListItemIcon>
                                                                    <IconTie stroke={1.5} size="1.3rem" />
                                                                </ListItemIcon>
                                                                <ListItemText
                                                                    primary={
                                                                        <Grid container spacing={1} justifyContent="space-between">
                                                                            <Grid item>
                                                                                <Typography variant="body2">
                                                                                    Convert back to BC Agent
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item>
                                                                                {/* <Chip
                                                                        label="02"
                                                                        size="small"
                                                                        sx={{
                                                                            bgcolor: theme.palette.warning.dark,
                                                                            color: theme.palette.background.default
                                                                        }}
                                                                    /> */}
                                                                            </Grid>
                                                                        </Grid>
                                                                    }
                                                                />
                                                            </ListItemButton>
                                                        )}
                                                    </>
                                                )}
                                                {/* {bcmodal && <BcAgreement />} */}
                                                {type === 'Merchant' && (
                                                    <ListItemButton
                                                        sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                        selected={selectedIndex === 1}
                                                        onClick={handleBcConversion}
                                                    >
                                                        <ListItemIcon>
                                                            <IconTie stroke={1.5} size="1.3rem" />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary={
                                                                <Grid container spacing={1} justifyContent="space-between">
                                                                    <Grid item>
                                                                        {/* {BcAgreement()} */}
                                                                        <Typography variant="body2">Upgrade to BC Agent</Typography>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        {/* <Chip
                                                                        label="02"
                                                                        size="small"
                                                                        sx={{
                                                                            bgcolor: theme.palette.warning.dark,
                                                                            color: theme.palette.background.default
                                                                        }}
                                                                    /> */}
                                                                    </Grid>
                                                                </Grid>
                                                            }
                                                        />
                                                    </ListItemButton>
                                                )}
                                                <ListItemButton
                                                    sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                    selected={selectedIndex === 4}
                                                    onClick={handleLogout}
                                                >
                                                    <ListItemIcon>
                                                        <IconLogout stroke={1.5} size="1.3rem" />
                                                    </ListItemIcon>
                                                    <ListItemText primary={<Typography variant="body2">Logout</Typography>} />
                                                </ListItemButton>
                                            </List>
                                        </Box>
                                    </PerfectScrollbar>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
            <ToastContainer />

            {merchantModalOpen && (
                <BcConvertModal
                    isOpen={merchantModalOpen}
                    handleclose={handleBcModalClose}
                    isPending={isBcConvertPending}
                    shouldshowEsign={shouldshowEsign}
                />
            )}
            <Spinner loading={isCheckLoading || isBackToBcLoading} />
            <ConfirmationDialog
                title={'Convert back to BC agent'}
                open={backToBcModalOpen}
                onClose={handleBackToBcClose}
                onConfirm={confirmBackToBc}
                confirmLoading={isBackToBcLoading}
                message="Are you sure you want to proceed with converting your account to a BC Agent with lower commission rates?"
            />
        </>
    );
};

export default ProfileSection;
