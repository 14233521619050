import { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { globalNotifyError } from 'views/utilities/AlertToast';
import { useMediaQuery, useTheme } from '@mui/material';

const OtpVerificationDialog = ({ open, onClose, onSubmit, onResend, otpLength = 6, isResendDisabled = false, resending, verifying }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [otp, setOtp] = useState('');

    // Handle OTP change
    const handleOtpChange = (event) => {
        const value = event.target.value;
        setOtp(value);
    };

    // Handle Submit
    const handleSubmit = () => {
        if (otp.length === otpLength) {
            onSubmit(otp);
            setOtp('');
        } else {
            globalNotifyError(`Please enter a ${otpLength}-digit OTP.`);
        }
    };
    const handleClose = () => {
        setOtp('');
        onClose();
    };
    const handleResend = () => {
        setOtp('');
        onResend();
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth={isMobile} // Full width for mobile view
            maxWidth={isMobile ? false : 'sm'} // Adjust for larger screen
            PaperProps={{
                sx: {
                    width: isMobile ? '100%' : '500px', // Custom width for desktop
                    maxWidth: '100%', // Ensure responsiveness
                    margin: isMobile ? 0 : 'auto'
                }
            }}
        >
            <DialogTitle>Verify OTP</DialogTitle>
            <DialogContent>
                <Box sx={{ marginTop: 2 }} display="flex" flexDirection="column" alignItems="center" gap={2}>
                    <TextField
                        label="Enter OTP"
                        variant="outlined"
                        value={otp}
                        onChange={handleOtpChange}
                        fullWidth
                        inputProps={{
                            maxLength: otpLength,
                            style: { textAlign: 'center', letterSpacing: '0.5em' } // Center and space out the OTP digits
                        }}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <LoadingButton loading={resending} onClick={handleResend} disabled={isResendDisabled}>
                    Resend OTP
                </LoadingButton>
                <LoadingButton loading={verifying} variant="contained" onClick={handleSubmit}>
                    Submit
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

OtpVerificationDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onResend: PropTypes.func.isRequired,
    otpLength: PropTypes.number,
    isResendDisabled: PropTypes.bool,
    resending: PropTypes.bool,
    verifying: PropTypes.bool
};

export default OtpVerificationDialog;
